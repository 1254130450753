import React from "react"
import { graphql } from "gatsby"

// components
import Layout from "../components/layout"
import Img from "gatsby-image"
import { Link } from "gatsby"

// icons
import { FaExternalLinkAlt } from "react-icons/fa"
import { FcNext } from "react-icons/fc"
import { FcPrevious } from "react-icons/fc"

const postList = ({ pageContext, data }) => {
  const { currentPage, pageTotal } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === pageTotal
  const prevPage = currentPage - 1 === 1 ? "/news" : `/news/${currentPage - 1}`
  const nextPage = isLast ? `/news/${pageTotal}` : `/news/${currentPage + 1}`

  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      {pageTotal > 1 && (
        <div className="mb-4 fixed pt-16 pb-2 w-full bg-white z-10000 mt-2 shadow-lg">
          <div className="container flex justify-between mx-auto px-5">
            {!isFirst && (
              <Link
                to={prevPage}
                className="flex items-center hover:text-blue-500 duration-200"
              >
                <FcPrevious className="inline-block" />
                Previous Page
              </Link>
            )}
            <span></span>
            {!isLast && (
              <Link
                to={nextPage}
                className="flex items-center hover:text-blue-500 duration-200"
              >
                Next Page
                <FcNext className="inline-block" />
              </Link>
            )}
          </div>
        </div>
      )}
      <section className="container mx-auto px-5 pt-24 pb-10">
        {posts.map(post => {
          const date = post.node.frontmatter.date.slice(0, 10).split("-")

          const formatedDate = [date[1], date[2], date[0]].join(" / ")

          const body = post.node.frontmatter.bodyText.split(". ")
          const preview = `${body[0]}. ${body[1]}.`

          return (
            <span key={`${post.node.id}`}>
              <div
                key={post.node.id}
                className="flex bg-gray-100 rounded hover:shadow-lg duration-200 mt-8"
              >
                <Link to={`/${post.node.id}`} className="flex w-full">
                  <div className="w-32">
                    <Img
                      fluid={
                        post.node.frontmatter.cardImg.childImageSharp.fluid
                      }
                      alt={post.node.frontmatter.title}
                      className="rounded-l"
                    />
                  </div>
                  <div className="flex flex-col px-6 sm:pr-1 xs:pr-1 w-full">
                    <div className="flex items-end md:items-start sm:items-start  xs:items-start flex-wrap mt-1">
                      <h1 className="text-2xl md:text-xl sm:text-xl xs:text-xl m-0 mr-4">
                        {post.node.frontmatter.title}
                      </h1>

                      <p className="m-0 text-gray-600 font-semibold text-xs">
                        {formatedDate}
                      </p>
                    </div>
                    <p className="text-sm xs:hidden m-0 mt-2">{preview}</p>
                  </div>
                </Link>
              </div>
              <p className="text-sm sm:hidden md:hidden lg:hidden xl:hidden m-0 mt-2">
                {preview}
              </p>
              <a
                href={post.node.frontmatter.link}
                target="_blank"
                rel="noopener noreferrer"
                className="m-0 text-blue-600 hover:text-blue-800 duration-200 cursor-pointer mb-4 text-sm"
              >
                <FaExternalLinkAlt className="inline-block mr-2" />
                Related Article Link
              </a>
            </span>
          )
        })}
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogPostsQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: frontmatter___date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            title
            cardImg {
              childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link
            bodyText
          }
        }
      }
    }
  }
`

export default postList
